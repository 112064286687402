var Auth = /** @class */ (function () {
    function Auth(row, settings, client, admin, hitched, assumed) {
        this.admin = admin;
        this.hitched = hitched;
        this.assumed = assumed;
        this.guest = false;
        this.sysadmin = false;
        this.settings = new Map();
        if (!row) {
            throw 'invalid_user';
        }
        this.id = row.id;
        this.last_name = row.last_name;
        this.first_name = row.first_name;
        this.email = row.email;
        this.login = row.login;
        this.guest = !!row.guest;
        this.sysadmin = !!row.sysadmin;
        this.company = client.name;
        this.loadSettings(settings);
    }
    Auth.prototype.loadSettings = function (rows) {
        if (rows === void 0) { rows = []; }
        this.settings = new Map(rows.map(function (row) {
            var val;
            try {
                val = JSON.parse(row.val);
            }
            catch (e) {
                val = row.val;
            }
            return [row.key, val];
        }));
    };
    Auth.prototype.setting = function (key) {
        return this.settings.get(key);
    };
    return Auth;
}());
export { Auth };
