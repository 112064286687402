import { Api } from './Api';
export * from './Admin';
export * from './Api';
export * from './Auth';
export * from './Barcode';
export * from './Client';
export * from './host';
export * from './Login';
export * from './Mobile';
export * from './Notify';
export * from './Qrcode';
export * from './sockets/socket-base';
export * from './sockets/socket';
export * from './Setting';
export * from './Templated';
export * from './tokenDecode';
export * from './User';
window.Api = Api;
