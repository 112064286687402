import Socket from 'socket.io-client';
import { BehaviorSubject } from 'rxjs';
export var RequestErrorStatus;
(function (RequestErrorStatus) {
    RequestErrorStatus[RequestErrorStatus["NO_SERVER_CONFIRMATION"] = 1] = "NO_SERVER_CONFIRMATION";
    RequestErrorStatus[RequestErrorStatus["SERVER_REQUEST_FAILED"] = 2] = "SERVER_REQUEST_FAILED";
})(RequestErrorStatus || (RequestErrorStatus = {}));
var _AuxiliumSocketClientBase = /** @class */ (function () {
    function _AuxiliumSocketClientBase(path) {
        this.socket = new BehaviorSubject(false);
        this._rid = 0;
        this.path = path || "https://REALM.auxiliumgroup.com:9394/";
        this._connect();
    }
    Object.defineProperty(_AuxiliumSocketClientBase.prototype, "defaultOptions", {
        get: function () {
            var path = this.path, secure = path.indexOf('https://') === 0;
            return {
                secure: secure,
                timeout: _AuxiliumSocketClientBase.CONNECTION_TIMEOUT_MS,
            };
        },
        enumerable: false,
        configurable: true
    });
    _AuxiliumSocketClientBase.prototype.emit = function (evtName, data, params) {
        if (params === void 0) { params = {}; }
        if (params && params.requestId)
            delete params.requestId;
        this._socket.emit(evtName, data, params);
    };
    _AuxiliumSocketClientBase.prototype.on = function (evtName, cb, socket) {
        if (socket === void 0) { socket = this._socket; }
        return this._wrapOn(evtName, cb, socket, false);
    };
    _AuxiliumSocketClientBase.prototype.once = function (evtName, cb, socket) {
        if (socket === void 0) { socket = this._socket; }
        return this._wrapOn(evtName, cb, socket, true);
    };
    _AuxiliumSocketClientBase.prototype._makeRequestId = function () {
        return (this._rid++) + '-' + new Date().getTime();
    };
    _AuxiliumSocketClientBase.prototype._connect = function () {
        var path = this.path, socket = this._socket = Socket(path, this.defaultOptions);
        this._attachListeners(socket);
    };
    _AuxiliumSocketClientBase.prototype._attachListeners = function (sock) {
        var _this = this;
        sock.on('connect', function () {
            _this.socket.next(sock.id);
        });
        sock.on('disconnect', function () {
            _this.socket.next(false);
        });
    };
    _AuxiliumSocketClientBase.prototype.disconnect = function () {
        var p = this._socket;
        if (p && p.connected) {
            p.close();
        }
    };
    _AuxiliumSocketClientBase.prototype.connect = function () {
        var pub = this._socket;
        if (!pub) {
            this._connect();
        }
        else if (pub.disconnected) {
            pub.open();
        }
    };
    /**
     * this is simply a wrapper function for on/once to return
     * a way to 'stop' listening without having to micromanage it
     */
    _AuxiliumSocketClientBase.prototype._wrapOn = function (evtName, cb, socket, once) {
        if (socket === void 0) { socket = this._socket; }
        var cancelled = false, resolved = false, received = false, emit;
        var wrapped = (function (data) {
            if (!cancelled) {
                cb(data);
                resolved = true;
            }
        }).bind(this);
        if (once) {
            emit = socket.once(evtName, wrapped);
        }
        else {
            emit = socket.on(evtName, wrapped);
            ;
        }
        var ret = {
            get resolved() { return resolved; },
            get cancelled() { return cancelled; },
            get received() { return received; },
            set received(rec) { received = rec; },
            off: function () {
                cancelled = true;
                if (emit) {
                    emit.off(evtName, wrapped);
                }
            }
        };
        return ret;
    };
    _AuxiliumSocketClientBase.CONNECTION_TIMEOUT_MS = 5000;
    return _AuxiliumSocketClientBase;
}());
export { _AuxiliumSocketClientBase };
