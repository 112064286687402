export var host = "\n--bg: #4c4cca;\n--primary: #4c4cca;\n--primary-fore: #fff;\n--accent: #F450A7;\n--accent-fore: #fff;\n--accent-dark: #00000022;\n--warn: #ff0000;\n--warn-fore: #fff;\n--disabled: #ddd;\n--disabled-fore: #888;\n--wrapper: #fff;\n--wrapper-fore: #888;\n--input: #fff;\n--input-fore: #111;\n--border-radius: 3px;\n";
// a dark profile
// export const host = `
// --bg: #666;
// --primary: #ff9900;
// --primary-fore: #fff;
// --accent: #32c832;
// --accent-fore: #fff;
// --accent-dark: #ffffff22;
// --warn: #ff0000;
// --warn-fore: #fff;
// --disabled: #ddd;
// --disabled-fore: #888;
// --wrapper: #000000b8;
// --wrapper-fore: #eee;
// --input: #fff;
// --input-fore: #111;
// --border-radius: 3px;
// `;
