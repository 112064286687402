var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { from, of, BehaviorSubject, iif, ReplaySubject, defer, forkJoin, zip } from 'rxjs';
import { map, switchMap, catchError, tap, filter, take, timeout, startWith } from 'rxjs/operators';
import { Auth } from './Auth';
import { Login } from './Login';
import { Notify } from './Notify';
import { ADMIN_KEYS } from './Admin';
import { Mobile } from './Mobile';
import { AuxiliumSocketClient } from './sockets/socket';
import { Barcode } from './Barcode';
import { Qrcode } from './Qrcode';
// DO NOT export this
var fileUploadKey = 'file';
export var RecordRequest;
(function (RecordRequest) {
    RecordRequest["insert"] = "xinsert";
    RecordRequest["update"] = "xupdate";
    RecordRequest["delete"] = "xdelete";
    RecordRequest["query"] = "query";
    RecordRequest["report"] = "report";
    RecordRequest["perms"] = "xperms";
})(RecordRequest || (RecordRequest = {}));
function _defaultDateToJson() {
    return this.toISOString();
}
function _customDateToJson() {
    return "".concat(this.getFullYear(), "/").concat(this.getMonth() + 1, "/").concat(this.getDate(), " ").concat(this.getHours(), ":").concat(this.getMinutes(), ":").concat(this.getSeconds());
}
function makeDateFromServerJson(param) {
    try {
        if (param) {
            var chunks = (param || '').split(' '), d = chunks[0].split('-').map(function (x) { return +x; }), t = chunks[1].split(':').map(function (x) { return +x; });
            return new Date(d[0], d[1] - 1 || 0, d[2], t[0] || 0, t[1] || 0, t[2] || 0, t[3] || 0);
        }
    }
    catch (err) { }
    return null;
}
/**
 * the server responds with 23:48:22 standard, so, no need for fun stuff, just a null check
 */
function makeTimeFromServerJson(param) {
    if (param) {
        return param;
    }
    return null;
}
var Api = /** @class */ (function () {
    function Api(config, mobile, // simple DI... lol
    barcode, qrcode) {
        if (mobile === void 0) { mobile = Mobile.instance(); }
        if (barcode === void 0) { barcode = Barcode.instance(); }
        if (qrcode === void 0) { qrcode = Qrcode.instance(); }
        this.mobile = mobile;
        this.barcode = barcode;
        this.qrcode = qrcode;
        this._auth = new BehaviorSubject(null);
        this.auth = this._auth.pipe(filter(function (a) { return !!a; }));
        this.token = new ReplaySubject();
        this.environmentSlices = {};
        this.settings = new BehaviorSubject(null);
        this.allowGuest = true;
        this.notify = new Notify();
        config = config || {};
        if (!Api.instance) {
            Api.instance = this;
        }
        this.spoke = config.spoke || location.hostname.split('.').shift();
        this.url = config.url || Api.defaultUrl;
        this.uploadUrl = config.uploadUrl || Api.defaultUploadUrl;
        this._fileUrl = config.fileUrl || Api.defaultFileUrl;
        this.settingsUrl = config.settingsUrl || Api.defaultSettingsUrl;
        this.socketUrl = (config === null || config === void 0 ? void 0 : config.socketUrl) || Api.defaultSocketUrl.replace("SPOKE", this.spoke);
        if (config.hasOwnProperty('allowGuest')) {
            this.allowGuest = !!config.allowGuest;
        }
        this._token = sessionStorage.getItem(this._getTokenKey()) || localStorage.getItem(this._getTokenKey());
        this.hitchWithAuthenticate = config.hitchWithAuthenticate || [];
        if (config.autoAuthenticate) {
            if (this.allowGuest || this._token) {
                this.authenticate()
                    .subscribe();
            }
            else {
                this.showLogin()
                    .subscribe();
            }
        }
        // preventSocketAutoConnect
        if (!config.preventSocketAutoConnect) {
            this.connectToSocketServer();
        }
    }
    Api.formatTokenForHeader = function (token) {
        return "Bearer ".concat(token);
    };
    Api.call = function (req, cb) {
        var _this = this;
        var i = Api.instance;
        if (!i) {
            console.warn('api has not been instantiated');
        }
        else {
            cb = cb || (function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                console.log.apply(_this, args);
            });
            i.request(req, { labelAs: 'api.debug' }).subscribe(cb);
        }
    };
    Api.prototype.getToken = function () { return this._token; };
    Api.prototype.setToken = function (token, useSession) {
        var key = this._getTokenKey();
        if (this._token !== token) {
            this._token = token;
            if (token) {
                (useSession ? sessionStorage : localStorage).setItem(key, token);
                this.token.next(token);
            }
            else {
                (useSession ? sessionStorage : localStorage).removeItem(key);
                this.token.next(null);
            }
        }
        // inform the mobile wrapper that the token changed, JUST in case
        this.mobile.updateToken(token);
    };
    Api.prototype.connectToSocketServer = function (url) {
        var _this = this;
        if (url === void 0) { url = this.socketUrl; }
        if (!url) {
            console.warn('no socket url provided');
            return;
        }
        try {
            var socket_1 = this.socket = new AuxiliumSocketClient(url);
            this.token
                .pipe(startWith(this._token))
                .subscribe(function (token) {
                var spoke = _this.spoke;
                if (token) {
                    socket_1.resume(token, spoke);
                }
            });
        }
        catch (err) {
            console.warn('unable to create socket client instance.  have you installed the peer dependancies?', { err: err, this: this });
        }
    };
    Api.prototype.getFileUrl = function (id, ignoreToken) {
        var url = "".concat(this._fileUrl, "?id=").concat(id);
        if (!ignoreToken) {
            var token = this.getToken();
            if (token) {
                url += "&token=".concat(token);
            }
        }
        return url;
    };
    Api.prototype.getTimezone = function () {
        return localStorage.getItem(Api._timezoneKey) || Intl.DateTimeFormat().resolvedOptions().timeZone;
    };
    Api.prototype.setTimezone = function (tz) {
        var key = Api._timezoneKey;
        if (tz) {
            localStorage.setItem(key, tz);
        }
        else {
            localStorage.removeItem(key);
        }
    };
    Api.prototype.getCurrentAuth = function () {
        return this._auth.getValue();
    };
    Api.prototype.isValidUser = function () {
        return this
            .request({ '$/auth/current': {} }, { labelAs: 'api.isValidUser' })
            .pipe(map(function (a) { return !a.guest; }));
    };
    Api.prototype.isSysAdmin = function () {
        return this
            .report('sysadmins', { where: ['$eq', ['$field', 'auth_ref'], ['$viewer']] })
            .pipe(catchError(function (err) { return of({ rows: [] }); }), map(function (r) { return r.rows && r.rows.length ? true : false; }));
    };
    Api.prototype.isTableAdmin = function () {
        return this
            .report('tableadmins', { where: ['$eq', ['$field', 'auth_ref'], ['$viewer']] })
            .pipe(catchError(function (err) { return of({ rows: [] }); }), map(function (r) { return r.rows && r.rows.length ? true : false; }));
    };
    Api.prototype.isUserAdmin = function () {
        return this
            .report('useradmins', { where: ['$eq', ['$field', 'auth_ref'], ['$viewer']] })
            .pipe(catchError(function (err) { return of({ rows: [] }); }), map(function (r) { return r.rows && r.rows.length ? true : false; }));
    };
    Api.prototype.isInRole = function (role) {
        return this.isInRoles([role]);
    };
    Api.prototype.isInRoles = function (role) {
        throw '@todo';
    };
    Api.prototype.showLogin = function (auth, assertionFn, assertionFailMessage, assertionContext) {
        var _this = this;
        var login = this._login || (this._login = new Login(this, auth)), settings = this.settings.getValue();
        if (!(settings === null || settings === void 0 ? void 0 : settings.length)) {
            return this
                .getSafeSettings()
                .pipe(switchMap(function () { return login.show(_this.allowGuest, null, assertionFn, assertionFailMessage || 'This account lacks the nescessary permissions to view this content.', assertionContext); }));
        }
        else {
            return login.show(this.allowGuest, null, assertionFn, assertionFailMessage || 'This account lacks the nescessary permissions to view this content.', assertionContext);
        }
    };
    Api.prototype.authenticate = function () {
        var _this = this;
        var adminCheckParams = { fields: { _validKey: ['$const', 1] }, where: ['$eq', ['$field', 'auth_ref'], ['$viewer']] }, toHitch = this.hitchWithAuthenticate;
        var hitched;
        if (toHitch.length) {
            hitched = [];
            toHitch.forEach(function (h) { return hitched.push(h.id, h.request); });
        }
        return this.request({
            auth: { '$/auth/current': {} },
            env: { '$/env/all': {} },
            client: { '$/env/client': {} },
            settings: { '$/env/settings/report': {}, $pop: 'rows' },
            admin: { '$/tools/do': [
                    ADMIN_KEYS.SYSTEM, { '$/env/sysadmins/report': adminCheckParams },
                    ADMIN_KEYS.TABLE, { '$/env/tableadmins/report': adminCheckParams },
                    ADMIN_KEYS.USER, { '$/env/useradmins/report': adminCheckParams },
                    'respond', {
                        system: { $_: "".concat(ADMIN_KEYS.SYSTEM, ":rows:0:_validKey"), $_else: 0 },
                        table: { $_: "".concat(ADMIN_KEYS.TABLE, ":rows:0:_validKey"), $_else: 0 },
                        user: { $_: "".concat(ADMIN_KEYS.USER, ":rows:0:_validKey"), $_else: 0 },
                    },
                ] },
            hitched: hitched ? { '$/tools/do': hitched } : null,
        }, { labelAs: 'api.authenticate' })
            .pipe(tap(function (r) {
            if (r && r.auth && r.auth.token && r.auth.token !== _this.getToken()) {
                _this.setToken(r.auth.token);
            }
            if (r && r.settings) {
                _this.settings.next(r.settings);
            }
            _this.environmentSlices = r.env;
            r.assumed = sessionStorage.getItem(_this._getTokenKey()) === r.auth.token;
        }), map(function (r) { return new Auth(r.auth, r.settings, r.client, r.admin, r.hitched, r.assumed); }), switchMap(function (a) { return _this.allowGuest || (a && !a.guest) ? of(a) : _this.showLogin(a); }), tap(function (a) { return _this._auth.next(a); }));
    };
    /**
     * sends instructions on how to reset the users password to the email on file
     *
     * @param login email addres (or login id) that is being used to send the email too
     */
    Api.prototype.sendRescueEmail = function (login) {
        // this call has a really goofy return signature, be carefull
        return this
            .request({ '$/auth/rescue_email': { user: { $or: { login: login, email: login } } } }, { labelAs: 'api.sendRescueEmail', ignoreGuestCheck: true })
            .pipe(catchError(function (err) { return of([{ notification: 0 }]); }), map(function (r) { return r && r[0] && r[0].notification; }));
    };
    Api.prototype.sendRescueSms = function (user, voiceCall) {
        if (voiceCall === void 0) { voiceCall = false; }
        return this
            .request({ '$/auth/mobile/generate': { user: user, method: voiceCall ? 'voice' : 'sms' } }, { labelAs: 'api.sendRescueSms', ignoreGuestCheck: true })
            .pipe(catchError(function (err) { return of({ status: 0 }); }), map(function (r) { return !!(r === null || r === void 0 ? void 0 : r.status); }));
    };
    //  $user, $pin, $password
    Api.prototype.updatePasswordFromRecovery = function (login, newPassword, verifyCode) {
        var _this = this;
        return this
            .request({ '$/auth/mobile/rescue': {
                user: login,
                password: newPassword,
                pin: verifyCode
            } }, { labelAs: 'api.updatePasswordFromRecovery', ignoreGuestCheck: true })
            .pipe(catchError(function (err) { return of(err); }), switchMap(function (r) {
            if (typeof r !== 'string' && ('token' in r)) {
                _this.setToken(r.token);
                return _this.authenticate();
            }
            else {
                switch (_this._makeErrorSafe(r)) {
                    case 'bad_or_expired_pin':
                        _this.notify.warn('Invalid or expired verification code.');
                        break;
                    default:
                        _this.notify.warn(r || 'An unknown error occured');
                }
            }
            return of(false);
        }));
    };
    Api.prototype.login = function (login, password, secret) {
        var _this = this;
        var frm = new FormData(), url = "".concat(this.url, "/").concat(Api.loginEndpoint);
        var cfg = {
            method: 'POST',
            body: frm,
            credentials: 'omit',
        };
        frm.append('login', login);
        frm.append('password', password);
        frm.append('realm', this.spoke);
        if (secret) {
            frm.append('secret', secret);
        }
        return defer(function () { return from(fetch(url, cfg)); })
            .pipe(switchMap(function (r) { return from(r.json()); }), map(function (resp) {
            if ('error' in resp) {
                switch (resp.error) {
                    case 'SMS':
                    case 'GOOGLE_AUTHENTICATOR':
                        return resp.error;
                }
                return false;
            }
            else {
                _this.setToken(resp.token);
                return true;
            }
        }), switchMap(function (a) { return iif(function () { return a === true; }, _this.authenticate(), of(a)); }));
    };
    Api.prototype.logout = function (all) {
        var _this = this;
        var key = this._getTokenKey(), session = sessionStorage.getItem(key), local = localStorage.getItem(key);
        var sessionObs;
        if (all && session && local) {
            sessionObs = defer(function () { return from(fetch("".concat(_this.url, "/").concat(Api.logoutEndpoint), {
                method: 'GET',
                credentials: 'omit',
                headers: _this._buildHeaders({}),
            })); });
            // now, ensure its wiped
            sessionStorage.removeItem(key);
            // and hack the token back to the local version
            this._token = local;
        }
        else {
            sessionObs = of(null);
        }
        return zip(defer(function () { return from(fetch("".concat(_this.url, "/").concat(Api.logoutEndpoint), {
            method: 'GET',
            credentials: 'omit',
            headers: _this._buildHeaders({}),
        })); }), sessionObs)
            .pipe(catchError(function () { return of(null); }), tap(function (r) { return _this._clearToken(); }), switchMap(function () { return _this.authenticate(); }));
    };
    Api.prototype.request = function (req, opt, isRetry) {
        var _this = this;
        if (opt === void 0) { opt = {}; }
        var args = { body: this._encode(req) };
        var url = opt.url || this.url;
        if (opt && opt.labelAs) {
            url = "".concat(url, "?").concat(opt.labelAs);
        }
        return this
            ._request(url, args, opt === null || opt === void 0 ? void 0 : opt.ignoreGuestCheck)
            .pipe(switchMap(function (r) { return from(r.json()); }), switchMap(function (r) {
            if (r && r.error) {
                var err = _this._makeErrorSafe(r.error);
                if (err === 'invalid_grant') {
                    _this._clearToken();
                    console.warn('invalid token format, clearing');
                    if (!isRetry) {
                        console.warn('retrying request...');
                        return _this.request(req, opt, true);
                    }
                }
                else if (/expired/i.test(err) && err !== 'bad_or_expired_pin') {
                    return _this._sessionExpired(opt.token || _this.getToken(), req, opt);
                }
                else {
                    console.warn('unhandled api.request', { req: req, error: r.error, opt: opt, response: r });
                }
                throw "".concat(r.error);
            }
            return of(r);
        }), map(function (r) { return _this._stripDebug(r); }), map(function (r) { return _this._recursiveDecodeResponse(r); }));
    };
    Api.prototype._makeErrorSafe = function (err) {
        return ('' + err).toLowerCase().trim().replace(/\s/g, '_');
    };
    Api.prototype.getSafeSettings = function () {
        var _this = this;
        return defer(function () { return from(fetch(_this.settingsUrl, {
            cache: 'no-cache',
            credentials: 'omit',
            method: 'get',
            headers: _this._buildHeaders({}),
        })); })
            .pipe(timeout(1500), // we only give it a 1s delay, because its not that important
        switchMap(function (r) { return from(r.json()); }), tap(function (rows) {
            _this.settings.next(rows);
        }), // share these
        catchError(function () { return of([]); }));
    };
    Api.prototype.upload = function (files, associate, updateSliceRow, updatePkCol) {
        var _this = this;
        if (updateSliceRow === void 0) { updateSliceRow = true; }
        if (updatePkCol === void 0) { updatePkCol = 'id'; }
        var slice = associate === null || associate === void 0 ? void 0 : associate.slice, field = associate === null || associate === void 0 ? void 0 : associate.field, row = associate === null || associate === void 0 ? void 0 : associate.record;
        return this._uploadRequest(files)
            .pipe(switchMap(function (resp) {
            var _a;
            if (associate) {
                var ids = Array.from(new Set(__spreadArray(__spreadArray([], Object.values(resp).map(function (r) { return r.id; }), true), (associate.ids || []), true)));
                return _this
                    .request({ '$/tools/do': [
                        'update', updateSliceRow ? { '!/slice/xupdate': { slice: slice, rows: [(_a = {}, _a[updatePkCol] = row, _a[field] = JSON.stringify(ids), _a)] } } : { ignore: 'me' },
                        'file', _this.makeAssociateRequest(ids, row, field, slice, true),
                    ] })
                    .pipe(map(function (associated) {
                    associated.files = resp;
                    return associated;
                }));
            }
            else {
                return of(resp);
            }
        }));
    };
    Api.prototype.associateUpload = function (ids, rowId, field, slice) {
        return this
            .request(this.makeAssociateRequest(ids, rowId, field, slice, false));
    };
    Api.prototype.makeAssociateRequest = function (ids, rowId, field, slice, partOfChain) {
        var _a;
        return _a = {},
            _a["".concat(partOfChain ? '!' : '$', "/tools/file/update")] = { row: rowId, field: field, slice: slice, ids: ids },
            _a;
    };
    /**
     *
     * @param files array of files to be sent to the server
     * @param row the row that is going to be inserted into the db
     * @param slice the target slice for the row
     * @param field the field in the row that the FILES are stored in
     * @param recordPkCol the primary column in the target slice (default 'id')
     */
    Api.prototype.uploadAndInsertRow = function (files, row, slice, field, recordPkCol) {
        var _this = this;
        if (recordPkCol === void 0) { recordPkCol = 'id'; }
        return this._uploadRequest(files)
            .pipe(switchMap(function (resp) {
            var _a;
            var fileIds = resp.map(function (r) { return r.id; });
            Object.assign(row, (_a = {}, _a[field] = JSON.stringify(fileIds), _a));
            return _this.request({ '$/tools/do': [
                    'insert', { '!/slice/xinsert': {
                            slice: slice,
                            rows: [row]
                        } },
                    'updateMap', _this.makeAssociateRequest(fileIds, { $_: "insert:keys:0" }, field, slice, true),
                    'responds', { $_: 'insert' },
                ] });
        }));
    };
    Api.prototype.report = function (slice, obj, fileFields) {
        if (obj === void 0) { obj = {}; }
        return this.request({ '$/tools/do': [
                'report', this.formatRecordRequest(slice, obj, RecordRequest.report, true),
                'files', (fileFields === null || fileFields === void 0 ? void 0 : fileFields.length) ? { '!/tools/file/byFieldsInRows': { fields: fileFields, rows: { $_: 'report:rows', $_else: [] } } } : [],
                'all', { $_: '*' },
            ] }, { labelAs: "api.report.".concat(slice) })
            .pipe(map(function (r) { return ({
            rows: r.report.rows,
            count: r.report.hasOwnProperty('count') ? r.report.count : null,
            files: r.files,
        }); }));
    };
    /**
     * runs a slice/report, and automatically bundles in the permissions for the rows
     *
     * @param slice the number (or, if saved in the environment, the name) of the slice to query
     * @param obj the params to be sent as the slice/report args
     * @param id the primary key column of the underlying slice (defaults to 'id')
     * @param fileFields if any fields in the response are files, and you want to include their meta data in the response
     */
    Api.prototype.reportWithPerms = function (slice, obj, id, fileFields) {
        var _this = this;
        if (obj === void 0) { obj = {}; }
        if (id === void 0) { id = 'id'; }
        obj = obj || {};
        obj.return = obj.return || {};
        obj.return.rows = true;
        obj.return.count = true;
        var req = { '$/tools/do': [
                'report', this.formatRecordRequest(slice, obj, RecordRequest.report, true),
                'pkeys', { '!/tools/column': { col: id, rows: { $_: 'report:rows', $_else: [] } } },
                'perms', this.formatRecordRequest(slice, { mask: 'ud', pkeys: { $_: 'pkeys', $_else: [] } }, RecordRequest.perms, true),
                'files', (fileFields === null || fileFields === void 0 ? void 0 : fileFields.length) ? { '!/tools/file/byFieldsInRows': { fields: fileFields, rows: { $_: 'report:rows' } } } : [],
                'all', {
                    report: { $_: 'report' },
                    perms: { $_: 'perms' },
                    files: { $_: 'files' },
                },
                // 'all', {$_: '*'},
            ] };
        return this.request(req, {
            labelAs: "api.reportWithPerms.".concat(slice),
        })
            .pipe(map(function (r) { return ({
            rows: _this.mixPermsIntoRows(r.report.rows, r.perms, id),
            count: r.report.hasOwnProperty('count') ? r.report.count : null,
            files: r.files,
        }); }));
    };
    Api.prototype.deleteRow = function (slice, pkey) {
        return this.deleteRows(slice, [pkey]);
    };
    Api.prototype.deleteRows = function (slice, pkeys) {
        var req = this.formatRecordRequest(slice, { pkeys: pkeys }, RecordRequest.delete);
        return this
            .request(req, { labelAs: "api.deleteRows.".concat(slice) })
            .pipe(catchError(function (err) {
            console.warn('an error occured\n', { request: req, error: err });
            return of({ tx: null, keys: [] });
        }), map(function (resp) {
            var keys = new Set(resp.keys || []), sentKeys = Array.from(new Set(pkeys)), success = sentKeys.every(function (id) { return keys.has(id); });
            return {
                success: success,
                transaction: resp.tx || null,
                keys: resp.keys || [],
            };
        }));
    };
    Api.prototype.insertRow = function (slice, row) {
        return this.insertRows(slice, [row]);
    };
    Api.prototype.insertRows = function (slice, rows) {
        var req = this.formatRecordRequest(slice, { rows: rows }, RecordRequest.insert);
        return this
            .request(req, { labelAs: "api.insertRows.".concat(slice) })
            .pipe(catchError(function (err) {
            console.warn('an error occured\n', { request: req, error: err });
            return of({ keys: [], tx: null });
        }), map(function (r) {
            var success = (r.keys || []).length === rows.length;
            return {
                success: success,
                transaction: r.tx || null,
                keys: r.keys || [],
            };
        }));
    };
    Api.prototype.updateRow = function (slice, row, idField) {
        return this.updateRows(slice, [row], idField);
    };
    Api.prototype.updateRows = function (slice, rows, idField) {
        if (idField === void 0) { idField = 'id'; }
        var req = this.formatRecordRequest(slice, { rows: rows }, RecordRequest.update);
        return this
            .request(req, { labelAs: "api.updateRows.".concat(slice) })
            .pipe(catchError(function (err) {
            console.warn('an error occured\n', { request: req, error: err });
            return of({ keys: [], tx: null });
        }), map(function (resp) {
            var keys = new Set(resp.keys || []), sentKeys = Array.from(new Set(rows.map(function (r) { return +r[idField]; }))), success = sentKeys.every(function (key) { return keys.has(key); });
            return {
                success: success,
                transaction: resp.tx,
                keys: resp.keys || [],
            };
        }));
    };
    Api.prototype.formatRecordRequest = function (slice, data, op, partOfChain) {
        var isNum = typeof slice === 'number', func = partOfChain ? '!' : '$', req = {};
        var path;
        data = data || {};
        if (isNum) {
            path = "".concat(func, "/slice/").concat(op);
            req[path] = Object.assign({ slice: slice }, data);
        }
        else {
            path = "".concat(func, "/env/").concat(slice, "/").concat(op);
            req[path] = data;
        }
        return req;
    };
    Api.prototype.mixPermsIntoRows = function (rows, perms, id) {
        if (id === void 0) { id = 'id'; }
        var d = new Set(perms.delete), u = perms.update || {};
        return rows.map(function (r) {
            var upd = u[r[id]] || {}, bits = {
                _deletable: d.has(r[id]),
                _updatable: new Set(Object
                    .keys(upd)
                    .map(function (k) { return upd[k] && upd[k].length && upd[k].some && upd[k].some(function (x) { return !!x; }) ? k : null; })
                    .filter(function (k) { return !!k; }))
            };
            return Object.assign(r, bits);
        });
    };
    /**
     * this function will let you become another user for
     * this session (unique to the window)
     * @param id the auth.id you want to assume
     */
    Api.prototype.assume = function (id) {
        var _this = this;
        return this
            .request({ '$/report/users/become': { id: id } })
            .pipe(switchMap(function (auth) {
            if (auth.token !== _this._token) {
                _this.setToken(auth.token, true);
                return _this.authenticate();
            }
            throw 'rejected';
        }), catchError(function (err) {
            throw 'rejected';
        }));
    };
    // mixFilesIntoRows<T>(rows: T[], files: ApiFilenameResponse, fileFields: (keyof T)[]) {
    // 	if (fileFields?.length) {
    // 		return rows.map(row => {
    // 			fileFields.forEach(field => {
    // 				if (row[field] && typeof row[field] === 'string' && /^\[.*\]$/.test('' + row[field])) {
    // 					try {
    // 						const ids = JSON.parse('' + row[field]) as number[];
    // 						(row as any)[`_files_${field}`] = ids.map(id => files[id]).filter(f => !!f);
    // 					} catch (err) {
    // 						console.warn('malformed json structure', row[field]);
    // 					}
    // 				}
    // 			});
    // 			return row;
    // 		});
    // 	}
    // 	return rows;
    // }
    Api.prototype._clearToken = function () {
        // check to see if we have a sessionToken..
        var key = this._getTokenKey(), session = sessionStorage.getItem(key);
        if (session) {
            sessionStorage.removeItem(key);
            this.setToken(localStorage.getItem(key) || null);
        }
        else {
            this.setToken(null);
        }
    };
    /**
     * @description defines a fallback user/pass to login as when auth fails
     * @warn 2FA will not work!!
     * @warn GUEST will be ignored if enabled
     * @param login email/login or whatever that is used to login
     * @param password password for auth account
     */
    Api.prototype.unauthorizedLoginAs = function (login, password, secret) {
        var guest = this.allowGuest;
        this._unauthorizedLoginAs = { login: login, password: password, secret: secret, previousAllowGuest: guest };
        if (guest) {
            this.allowGuest = false;
            console.warn("forcing a user when guest access is enabled will prevent the guest account from being used until it is cleared via 'clearForceUser()'");
        }
    };
    Api.prototype.clearUnauthorizedLoginAs = function () {
        var unauth = this._unauthorizedLoginAs;
        if (unauth) {
            this.allowGuest = !!unauth.previousAllowGuest;
        }
        this._unauthorizedLoginAs = null;
    };
    Api.prototype._request = function (url, cfg, ignoreGuestCheck) {
        var _this = this;
        if (ignoreGuestCheck === void 0) { ignoreGuestCheck = false; }
        if (!('cache' in cfg))
            cfg.cache = 'no-cache';
        if (!('credentials' in cfg))
            cfg.credentials = 'omit';
        if (!('method' in cfg))
            cfg.method = 'post';
        cfg.headers = this._buildHeaders(cfg);
        var token = this.getToken();
        if (!token && !ignoreGuestCheck) {
            var unauth = this._unauthorizedLoginAs;
            if (unauth) {
                var login = unauth.login, password = unauth.password, secret = unauth.secret;
                this.login(login, password, secret)
                    .subscribe(function (r) {
                    if (r) {
                        cfg.headers = _this._buildHeaders(cfg);
                        return defer(function () { return from(fetch(url, cfg)); });
                    }
                    else {
                        throw 'forceUser failed';
                    }
                });
            }
            else if (this.allowGuest) {
                return this._loginAsGuest()
                    .pipe(switchMap(function () {
                    // rebuild the headers now, the token should be stored!
                    cfg.headers = _this._buildHeaders(cfg);
                    return defer(function () { return from(fetch(url, cfg)); });
                }));
            }
            else {
                this.showLogin()
                    .subscribe();
                throw 'guest_restricted';
            }
        }
        else {
            return defer(function () { return from(fetch(url, cfg)); });
        }
    };
    Api.prototype.loginAsGuest = function () {
        return this._loginAsGuest();
    };
    Api.prototype._loginAsGuest = function () {
        var _this = this;
        return defer(function () { return from(fetch("".concat(_this.url, "/").concat(Api.guestEndpoint), {
            method: 'GET',
            credentials: 'omit',
            headers: _this._buildHeaders({}, true),
        })); })
            .pipe(switchMap(function (r) { return from(r.json()); }), map(function (r) {
            var token = r.token || null;
            _this.setToken(token);
            return token;
        }));
    };
    Api.prototype._buildHeaders = function (cfg, ignoreToken) {
        var _a;
        if (ignoreToken === void 0) { ignoreToken = false; }
        var headers = new Headers('headers' in cfg ? cfg.headers : Api.defaultHeaders), token = this.getToken(), socket = (_a = this.socket) === null || _a === void 0 ? void 0 : _a.id;
        if (token && !ignoreToken) {
            headers.set(Api.tokenHeader, Api.formatTokenForHeader(token));
        }
        else if (this.spoke) {
            headers.set(Api.spokeHeader, this.spoke);
        }
        if (socket) {
            headers.set(Api.socketHeader, socket);
        }
        headers.set('timezone', this.getTimezone());
        return headers;
    };
    // private _isAuthRow(resp: any): resp is AuthRow {
    // 	return !!(resp as AuthRow).id;
    // }
    // should we store a cached request signature
    Api.prototype._sessionExpired = function (token, username, req, opt) {
        var _this = this;
        if (opt === void 0) { opt = {}; }
        if (username) {
            var login = this._login || (this._login = new Login(this, null));
            return login
                .show(this.allowGuest, { token: token, username: username })
                .pipe(filter(function (a) { return !!a; }), take(1), switchMap(function (auth) { return _this.request(req, opt); }));
        }
        else {
            console.warn('no username found, unable to resume');
        }
        throw 'session_expired';
    };
    // this takes a file array, and makes each call its own, and
    // resolves as such
    Api.prototype._uploadRequest = function (files) {
        var _this = this;
        var url = this.uploadUrl;
        return forkJoin((files instanceof FileList ? Array.from(files) : files)
            .map(function (file) {
            var formData = new FormData();
            formData.append(fileUploadKey, file, file.name);
            return _this
                ._request(url, { body: formData, headers: {} }) // intentionally send empty headers
                .pipe(switchMap(function (r) { return from(r.json()); }), map(function (resp) { return resp.files[fileUploadKey]; }), map(function (f) {
                if ('error' in f) {
                    throw f.error;
                }
                f.url = _this.getFileUrl(f.id);
                return f;
            }));
        }));
    };
    Api.prototype._encode = function (obj) {
        Date.prototype.toJSON = _customDateToJson;
        var ret = JSON.stringify(obj);
        Date.prototype.toJSON = _defaultDateToJson;
        return ret;
    };
    Api.prototype._recursiveDecodeResponse = function (resp) {
        var _this = this;
        var t = Object.prototype.toString.call(resp);
        switch (t) {
            case '[object Object]':
                Object.keys(resp)
                    .forEach(function (key) {
                    if (Api.conversions.has(key)) {
                        resp = Api.conversions.get(key)(resp);
                    }
                    else {
                        resp[key] = _this._recursiveDecodeResponse(resp[key]);
                    }
                });
                break;
            case '[object Array]':
                resp = resp.map(function (v) { return _this._recursiveDecodeResponse(v); });
                break;
        }
        return resp;
    };
    Api.prototype._stripDebug = function (resp) {
        if (resp && resp['debug-log']) {
            delete resp['debug-log'];
        }
        return resp;
    };
    Api.prototype._getTokenKey = function () {
        var url = this.url;
        return "authorization-".concat(url);
    };
    Api.conversions = new Map([
        ['$/tools/date', function (val) { return makeDateFromServerJson(val['$/tools/date']); }],
        ['$/tools/date_strict', function (val) { return makeDateFromServerJson(val['$/tools/date_strict']); }],
        ['$/tools/time_strict', function (val) { return makeTimeFromServerJson(val['$/tools/time_strict']); }],
    ]);
    Api.defaultHeaders = {
        'Content-Type': 'application/json; charset=utf-8',
    };
    Api.defaultUrl = "https://api.datalynk.ca";
    Api.defaultUploadUrl = "https://api.datalynk.ca/upload";
    Api.defaultFileUrl = "https://api.datalynk.ca/file";
    Api.defaultSettingsUrl = "https://api.datalynk.ca/settings";
    Api.defaultSocketUrl = 'https://SPOKE.auxiliumgroup.com:9394';
    Api.tokenHeader = 'Authorization';
    Api.spokeHeader = 'Realm';
    Api.socketHeader = 'socketId';
    Api.loginEndpoint = 'login';
    Api.logoutEndpoint = 'logout';
    Api.guestEndpoint = 'guest';
    Api.settingsEndpoint = 'settings';
    Api._timezoneKey = 'api.timezone';
    return Api;
}());
export { Api };
