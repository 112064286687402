var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import JsBarcode from 'jsbarcode';
var Barcode = /** @class */ (function () {
    function Barcode() {
    }
    Barcode.instance = function (win) {
        if (win === void 0) { win = window; }
        var inst = Barcode._instance;
        if (!inst) {
            inst = Barcode._instance = new Barcode();
        }
        return inst;
    };
    Barcode.prototype.toCanvas = function (value, config) {
        if (config === void 0) { config = {}; }
        var canvas = document.createElement('canvas');
        JsBarcode(canvas, value, __assign(__assign({}, Barcode.config), config));
        return canvas;
    };
    Barcode.config = {
        height: 50,
        displayValue: false,
    };
    return Barcode;
}());
export { Barcode };
