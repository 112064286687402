var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Templated } from "./Templated";
import { host } from "./host";
var Notify = /** @class */ (function (_super) {
    __extends(Notify, _super);
    function Notify() {
        var _this = _super.call(this, {
            tag: 'api-notify',
            template: "<div class=\"notify\"><div class=\"message\"></div></div>",
            style: "\n:host {\n\tdisplay: inline-block;\n\tposition: fixed;\n\tmax-width: 300px;\n\tz-index: 99999;\n\t".concat(host, "\n}\n.notify {\n\tpadding: 12px;\n\tborder-radius: 4px;\n\tfont-size: 14px;\n\tfont-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\";\n\ttransition: opacity 150ms linear;\n}\n.notify.hide {\n\topacity: 0;\n}\n.notify.warn {\n\tbackground-color: var(--warn);\n\tcolor: var(--warn-fore);\n}\n.notify.accent {\n\tbackground-color: var(--accent);\n\tcolor: var(--accent-fore);\n}\n"),
        }) || this;
        _this._containerNode = _this.querySelector('.notify');
        _this._messageNode = _this.querySelector('.message');
        return _this;
    }
    Notify.prototype.warn = function (message, durationMs) {
        this._messageNode.innerHTML = message;
        this._setColor('warn');
        this._show(durationMs);
    };
    Notify.prototype.inform = function (message, durationMs) {
        this._messageNode.innerHTML = message;
        this._setColor('accent');
        this._show(durationMs);
    };
    Notify.prototype._setColor = function (color) {
        if (color === void 0) { color = 'primary'; }
        this._containerNode.className = "notify ".concat(color);
    };
    Notify.prototype._show = function (durationMs) {
        var _this = this;
        if (durationMs === void 0) { durationMs = 4000; }
        if (this._durationTimeout)
            clearTimeout(this._durationTimeout);
        setTimeout(function () {
            var b = document.body, n = _this.domNode, cont = _this._containerNode;
            n.style.removeProperty('top');
            n.style.removeProperty('left');
            cont.classList.add('hide');
            b.insertBefore(n, b.firstChild);
            var innerH = innerHeight, innerW = innerWidth, h = cont.scrollHeight, w = cont.scrollWidth;
            b.addEventListener('click', function () { return _this.dismiss(); });
            n.style.top = (innerH / 2 - h / 2) + 'px';
            n.style.left = (innerW / 2 - w / 2) + 'px';
            cont.classList.remove('hide');
            if (durationMs) {
                _this._durationTimeout = +setTimeout(function () { return _this.dismiss(); }, durationMs);
            }
        });
    };
    Notify.prototype.dismiss = function () {
        var n = this.domNode, par = n.parentNode, t = this._durationTimeout;
        if (t)
            clearTimeout(t);
        if (par)
            par.removeChild(n);
    };
    return Notify;
}(Templated));
export { Notify };
