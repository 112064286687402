var Templated = /** @class */ (function () {
    function Templated(params) {
        this._build(params);
    }
    Templated.prototype.getElementById = function (id) {
        return this._shadow.getElementById(id);
    };
    Templated.prototype.querySelector = function (qry) {
        return this._shadow.querySelector(qry);
    };
    Templated.prototype.querySelectorAll = function (qry) {
        return Array.from(this._shadow.querySelectorAll(qry));
    };
    Templated.prototype._build = function (p) {
        var tpl = p.template, root = document.createElement(p.tag), shadow = this._shadow = root.attachShadow({ mode: 'closed' }), tempNode = document.createElement('div'), style = document.createElement('style'), injectedStyle = this.styleNode = document.createElement('style');
        style.textContent = p.style || '';
        shadow.appendChild(style);
        shadow.appendChild(injectedStyle);
        tempNode.innerHTML = tpl;
        Array.from(tempNode.childNodes)
            .forEach(function (n) {
            shadow.appendChild(n);
        });
        this.domNode = root;
    };
    return Templated;
}());
export { Templated };
