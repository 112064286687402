import { Subject } from "rxjs";
var IOS_TYPE;
(function (IOS_TYPE) {
    IOS_TYPE["IS_TRACKING"] = "isTracking";
    IOS_TYPE["SOUND_ALARM"] = "soundAlarm";
    IOS_TYPE["SET_VOLUME"] = "setVolume";
    IOS_TYPE["START_TRACKING"] = "startTracking";
    IOS_TYPE["START_WALKIE_TALKIE"] = "startLynk";
    IOS_TYPE["STOP_TRACKING"] = "stopTracking";
    IOS_TYPE["UPDATE_TOKEN"] = "updateToken";
})(IOS_TYPE || (IOS_TYPE = {}));
var Mobile = /** @class */ (function () {
    function Mobile() {
        this._captureRequests = new Set();
        this.eventListeners = {};
    }
    Mobile.instance = function (win) {
        if (win === void 0) { win = window; }
        var inst = Mobile._instance;
        if (!inst) {
            inst = Mobile._instance = new Mobile();
            if (!win.my)
                win.my = { namespace: {} };
            if (!win.my.namespace)
                win.my.namespace = {};
            win.my.namespace.publicFunc = inst._captureFrame.bind(inst);
        }
        return inst;
    };
    /**
     * @description add a callback to an event that the AMS instance would fire
     * @param eventName name of the event to listen for
     * @param callbackFn your callback function
     * @todo this should really whitelist the available event names, and provide
     * better function signatures
     * also, i'm not certain what is calling these, and if it relies on the 'eventListeners'
     * key being present in some global namespace
     *
     * also, why no removeeventListener?  how do we stop?
     */
    Mobile.prototype.addEventListener = function (eventName, callbackFn) {
        var el = this.eventListeners, arr = el[eventName] || (el[eventName] = []);
        arr.push(callbackFn);
    };
    /**
     * @description launches the front facing camera and takes a picture, closing
     * it immediately (ANDROID only)
     * @param filename string name for the file (with .ext! or the upload may fail)
     * @param xyMaxPixels max width or height of the image, in pixels
     * @notes Android only; call canCaptureFrontPhoto if you want to check
     * @return Subject<File | null> as subject that resolves when a file is returned,
     * or NULL if not available/cancelled
     */
    Mobile.prototype.captureFrontPhoto = function (filename, xyMaxPixels) {
        var _this = this;
        var sub = new Subject(), req = { filename: filename, sub: sub }, subs = this._captureRequests;
        if (!filename) {
            req.filename = "capture_".concat(++Mobile._autoId, ".jpg");
        }
        subs.add(req);
        setTimeout(function () {
            var android = _this._android();
            if (android) {
                _this._captureFrontPhoto(xyMaxPixels);
            }
            else {
                _this._invalidPlatformMessage('catpureFrontPhoto');
                sub.next(null);
                sub.complete();
                subs.delete(req);
            }
        }, 1);
        return sub;
    };
    /**
     * @description popup a 'toast' message on the device
     * @param message string message to display
     */
    Mobile.prototype.createToast = function (message) {
        var android = this._android();
        message = String(message || '');
        if (android) {
            if (message.length) {
                android.createToast(message);
            }
            else {
                console.warn('createToast invalid message length');
            }
        }
        else {
            this._invalidPlatformMessage('createToast');
        }
    };
    /**
     * @description fires events
     * @param evtName name of the event to fire...
     * @todo Should only be used by AMS app. This fires events reflecting the state of the AMS app
     *
     * whatever that means..
     */
    Mobile.prototype.fireEvent = function (evtName) {
        (this.eventListeners[evtName] || [])
            .forEach(function (evt) { return evt(); });
    };
    /**
     * @description checks if we are currently tracking
     * @param status its supposed to be boolean, but i'll have to ask Andre, as theres some
     * giggiggitty hapenning, so i just cloned it..
     */
    Mobile.prototype.isTracking = function (status) {
        var android = this._android(), ios = this._ios();
        if (android) {
            return android.isTracking();
        }
        else if (ios) {
            this._iosRoute(IOS_TYPE.IS_TRACKING);
            // i'm not going to pretend to understand what the fuck this is doing..
            if (status === null) {
                return 'start';
            }
            else {
                return status;
            }
        }
        else {
            this._notAvailableMessage();
            return false;
        }
    };
    /**
     * @description opens the walkie talkie
     */
    Mobile.prototype.openWalkieTalkie = function () {
        var android = this._android(), ios = this._ios();
        if (android) {
            android.openWalkieTalkie();
        }
        else if (ios) {
            this._iosRoute(IOS_TYPE.START_WALKIE_TALKIE);
        }
        else {
            this._notAvailableMessage();
        }
    };
    /**
     * @description set the device volume level
     * @param volume from 0 to 1
     */
    Mobile.prototype.setVolume = function (volume) {
        if ((!volume && volume !== 0) || volume < 0 || volume > 1) {
            console.warn('invalid volume level, must be between 0 and 1');
            return;
        }
        var android = this._android(), ios = this._ios();
        if (android) {
            android.setVolume(volume);
        }
        else if (ios) {
            this._iosRoute(IOS_TYPE.SET_VOLUME, { volume: volume });
        }
        else {
            this._notAvailableMessage();
        }
    };
    /**
     * @description plays an audible alarm
     */
    Mobile.prototype.soundAlarm = function () {
        var android = this._android(), ios = this._ios();
        if (android) {
            android.soundAlarm();
        }
        else if (ios) {
            this._iosRoute(IOS_TYPE.SOUND_ALARM);
        }
        else {
            this._notAvailableMessage();
        }
    };
    /**
     * @description Begin tracking GPS location of device for {minutes} in Datalynk
     * @param minutes number of minutes to track phone (-1 for user default, RECOMMENDED)
     * @returns false if not available, or a function that can be called to stopTracking
     */
    Mobile.prototype.startTracking = function (minutes) {
        minutes = minutes || -1;
        var android = this._android(), ios = this._ios(), retFn = this.stopTracking.bind(this);
        if (android) {
            android.startTracking(minutes);
        }
        else if (ios) {
            this._iosRoute(IOS_TYPE.START_TRACKING, { time: minutes });
        }
        else {
            this._notAvailableMessage();
        }
        return retFn;
    };
    /**
     * @description stops tracking
     */
    Mobile.prototype.stopTracking = function () {
        var android = this._android(), ios = this._ios();
        if (android) {
            android.stopTracking();
        }
        else if (ios) {
            this._iosRoute(IOS_TYPE.STOP_TRACKING);
        }
        else {
            this._notAvailableMessage();
        }
    };
    Mobile.prototype.updateToken = function (token) {
        token = token || ''; // force to empty string on undef/null/empty/0
        var android = this._android(), ios = this._ios();
        if (android) {
            android.updateToken(token);
        }
        else if (ios) {
            this._iosRoute(IOS_TYPE.UPDATE_TOKEN, { token: token });
        }
        else {
            this._notAvailableMessage();
        }
    };
    // returns the instance IF it is android
    Mobile.prototype._android = function (win) {
        if (win === void 0) { win = window; }
        return (win === null || win === void 0 ? void 0 : win.AMS) ? (win === null || win === void 0 ? void 0 : win.AMS) === 'ios' ? null : win.AMS : null;
    };
    /**
     *
     * @description this just calls our subscriber and cleans up
     * after itself when it hears requests from the mobile app
     *
     * @param base64 data sent from the mobile app
     */
    Mobile.prototype._captureFrame = function (base64) {
        var subs = this._captureRequests;
        if (subs.size) {
            if (base64) {
                var bin = atob(base64), len = bin.length, bytes = new Uint8Array(len);
                for (var i = 0; i < len; i++) {
                    bytes[i] = bin.charCodeAt(i);
                }
                var blob_1 = new Blob([bytes.buffer], { type: 'image/jpeg' });
                subs.forEach(function (params) {
                    var file = new File([blob_1], params.filename), sub = params.sub;
                    params.sub.next(file);
                    sub.next(file);
                    sub.complete();
                    subs.delete(params);
                });
            }
            else {
                subs.forEach(function (p) {
                    p.sub.next(null);
                    p.sub.complete();
                    subs.delete(p);
                });
            }
        }
    };
    Mobile.prototype._captureFrontPhoto = function (xyMaxPixels) {
        var android = this._android();
        if (android) {
            android.openFrontCamera(xyMaxPixels);
        }
        else {
            this._invalidPlatformMessage('captureFrontPhoto');
        }
    };
    // returns the string IF it is ios
    Mobile.prototype._ios = function (win) {
        if (win === void 0) { win = window; }
        return (win === null || win === void 0 ? void 0 : win.AMS) ? (win === null || win === void 0 ? void 0 : win.AMS) === 'ios' ? win.AMS : null : null;
    };
    // handles the 'location.href' mapping to the app, basic check to ensure
    // that we've registered the key (for type safety);
    Mobile.prototype._iosRoute = function (type, obj) {
        if (obj === void 0) { obj = {}; }
        var payload = JSON.stringify(Object.assign(obj, { type: type }));
        location.href = "auxmobile://".concat(payload);
    };
    // just a wrapper to make loggin a bit easier/cleaner
    Mobile.prototype._invalidPlatformMessage = function (funcName, platformTarget) {
        if (platformTarget === void 0) { platformTarget = 'android'; }
        console.warn("\"".concat(funcName, "\" requires the \"").concat(platformTarget, "\" platform to be loaded"));
    };
    Mobile.prototype._notAvailableMessage = function () {
        console.warn("AMS not found");
    };
    Mobile._autoId = 0;
    return Mobile;
}());
export { Mobile };
