var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as _qrCode from 'qrcode';
var Qrcode = /** @class */ (function () {
    function Qrcode() {
    }
    Qrcode.instance = function (win) {
        if (win === void 0) { win = window; }
        var inst = Qrcode._instance;
        if (!inst) {
            inst = Qrcode._instance = new Qrcode();
        }
        return inst;
    };
    Qrcode.prototype.toCanvas = function (value, config) {
        if (config === void 0) { config = {}; }
        var canvas = document.createElement('canvas');
        _qrCode.toCanvas(canvas, value, __assign(__assign({}, Qrcode.config), config));
        return canvas;
    };
    Qrcode.config = {};
    return Qrcode;
}());
export { Qrcode };
