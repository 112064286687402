/**
 * this is unproven/tested
 */
export function tokenDecode(token) {
    var _a;
    try {
        var v = (_a = "".concat(token || '').split('.', 2)) === null || _a === void 0 ? void 0 : _a[1], raw = JSON
            .parse(atob(v
            .replace(/-/g, '+')
            .replace(/_/g, '/')
            .replace(/\=+$/m, '')));
        return Object.assign({}, raw, {
            exp: new Date(raw.exp + 1000),
            iss: new Date(raw.iss + 1000),
        });
    }
    catch (err) {
        console.warn('tokenDecode failure', err);
    }
    return null;
}
