var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { _AuxiliumSocketClientBase } from './socket-base';
import { BehaviorSubject, Subject } from 'rxjs';
var AuxiliumSocketClient = /** @class */ (function (_super) {
    __extends(AuxiliumSocketClient, _super);
    function AuxiliumSocketClient(path) {
        var _this = _super.call(this, path) || this;
        _this.offline = new BehaviorSubject(false);
        _this.socketId = new BehaviorSubject(null);
        _this._firstRun = true;
        _this._onReportEventsObservers = {};
        _this._onRecordChangesObservers = {}; // @@TODO, tighten the spec
        _this._onCommentChangesObservers = {};
        _this._onSliceChangesObservers = {};
        _this._tokens = new Map();
        _this.defaultSpokeName = '';
        _this._init();
        return _this;
    }
    Object.defineProperty(AuxiliumSocketClient.prototype, "id", {
        get: function () {
            return this.socket.getValue();
        },
        enumerable: false,
        configurable: true
    });
    AuxiliumSocketClient.prototype._init = function () {
        var _this = this;
        // when our connection status changes...
        this.socket.subscribe(function (id) {
            if (id) {
                console.log('socket: connected', id);
                _this.offline.next(false);
                if (!_this._firstRun) {
                    _this._resumeAll();
                    // this.resume(this.token, this.defaultSpokeName);// this.spoke.next();
                }
                _this._firstRun = false;
            }
            else {
                if (!_this._firstRun) {
                    console.log('socket: disconnected', id);
                }
                _this.offline.next(true);
            }
            _this.socketId.next(id || null);
        });
        this.on('spoke-authorized', function (data) {
            var spoke = data.auth.spoke || data.spoke;
            _this._updateToken(spoke, data.auth.token);
            var onRepEvent = _this._onReportEventsObservers[spoke], onRecChange = _this._onRecordChangesObservers[spoke], onComChange = _this._onCommentChangesObservers[spoke], onSliChange = _this._onSliceChangesObservers[spoke];
            if (onRepEvent) {
                Array.from(onRepEvent.keys()).forEach(function (sliceId) { return _this.onReportEvents(sliceId, spoke); });
            }
            if (onRecChange) {
                Array
                    .from(onRecChange.keys())
                    .forEach(function (sliceId) { return _this.onRecordChanges(sliceId, spoke); });
            }
            if (onComChange) {
                Array
                    .from(onComChange.keys())
                    .forEach(function (sliceId) { return _this.onCommentChanges(sliceId, spoke); });
            }
            if (onSliChange) {
                Array
                    .from(onSliChange.keys())
                    .forEach(function (sliceId) { return _this.onSliceChanges(sliceId, spoke); });
            }
        });
        this.on('unauthorized', function (data) {
            _this._removeToken(data.spoke);
        });
        this.on('slice-report-event', (function (data) { return _this._onReportEvents(data); }));
        this.on('slice-changes', (function (data) { return _this._onSliceChanges(data); }));
        this.on('slice-records-changed', function (data) { return _this._onRecordChanges(data); });
        this.on('slice-comments-changed', function (data) { return _this._onCommentChanges(data); });
    };
    AuxiliumSocketClient.prototype.emit = function (evtName, data, params) {
        if (data === void 0) { data = {}; }
        if (params === void 0) { params = {}; }
        if (!params)
            params = {};
        if (!params.spoke)
            params.spoke = this.defaultSpokeName;
        if (!params.token)
            params.token = this.getToken(params.spoke);
        return _super.prototype.emit.call(this, evtName, data, params);
    };
    AuxiliumSocketClient.prototype._resumeAll = function () {
        var _this = this;
        this._tokens
            .forEach(function (spoke, token) {
            _this.resume(token, spoke);
        });
    };
    AuxiliumSocketClient.prototype._updateToken = function (spoke, token) {
        this._tokens.set(spoke, token);
    };
    AuxiliumSocketClient.prototype._removeToken = function (spoke) {
        this._tokens.delete(spoke);
    };
    AuxiliumSocketClient.prototype.getToken = function (spoke) {
        return this._tokens.get(spoke) || null;
    };
    /**
     * auth endpoints
     */
    AuxiliumSocketClient.prototype.resume = function (token, spoke) {
        if (!token && !spoke) {
            console.warn('\n\nno token passed, unable to resume', { token: token, spoke: spoke }, '\n\n');
        }
        else {
            this.emit('join-spoke', null, { spoke: spoke, token: token });
        }
        // update it, if either is null, its ok
        this._updateToken(spoke, token);
    };
    AuxiliumSocketClient.prototype.login = function (login, password, params) {
        this.emit('login', { login: login, password: password }, params);
    };
    AuxiliumSocketClient.prototype.logout = function (spoke, params) {
        if (params === void 0) { params = {}; }
        params.spoke = spoke || params.spoke;
        this.emit('logout', null, params);
    };
    AuxiliumSocketClient.prototype.onReportEvents = function (slice, spoke) {
        if (spoke === void 0) { spoke = this.defaultSpokeName; }
        var cache = this._onReportEventsObservers;
        var slices = cache[spoke];
        if (!slices) {
            slices = new Map();
            cache[spoke] = slices;
        }
        var existing = slices.get(slice);
        if (!existing) {
            slices.set(slice, existing = new Subject());
            this.on('report-event', function (evt) {
                if (evt.slice === slice) {
                    existing.next(evt.data);
                }
            });
            this.emit('report-event', { slice: slice }, { spoke: spoke });
        }
        return existing;
    };
    AuxiliumSocketClient.prototype.onSliceChanges = function (slice, spoke) {
        if (spoke === void 0) { spoke = this.defaultSpokeName; }
        var cache = this._onSliceChangesObservers;
        var slices = cache[spoke];
        if (!slices) {
            slices = new Map();
            cache[spoke] = slices;
        }
        var existing = slices.get(slice);
        if (!existing) {
            existing = new Subject();
            slices.set(slice, existing);
            this.on('slice-changes', function (evt) {
                if (evt.slice === slice) {
                    existing.next(evt.data);
                }
            });
            this.emit('slice-changes', { slice: slice }, { spoke: spoke });
        }
        return existing;
    };
    AuxiliumSocketClient.prototype.onCommentChanges = function (slice, spoke) {
        if (spoke === void 0) { spoke = this.defaultSpokeName; }
        var cache = this._onCommentChangesObservers;
        var slices = cache[spoke];
        if (!slices) {
            slices = new Map();
            cache[spoke] = slices;
        }
        var existing = slices.get(slice);
        if (!existing) {
            existing = new Subject();
            slices.set(slice, existing);
            this.on('comment-changes', function (evt) {
                if (evt.slice === slice) {
                    existing.next(evt.data);
                }
            });
        }
        this.emit('slice-comment-changes', { slice: slice }, { spoke: spoke });
        return existing;
    };
    /**
     * slice/record based notifications
     */
    AuxiliumSocketClient.prototype.onRecordChanges = function (slice, spoke) {
        if (spoke === void 0) { spoke = this.defaultSpokeName; }
        var cache = this._onRecordChangesObservers;
        var slices = cache[spoke];
        if (!slices) {
            slices = new Map();
            cache[spoke] = slices;
        }
        var existing = slices.get(slice);
        if (!existing) {
            existing = new Subject();
            slices.set(slice, existing);
            // listen to changes here and 'add' them to the existing
            this.on('record-changes', function (evt) {
                if (evt.slice === slice) {
                    existing.next(evt.data);
                }
            });
        }
        this.emit('slice-record-changes', { slice: slice }, { spoke: spoke });
        return existing;
    };
    AuxiliumSocketClient.prototype.testRecordChanges = function (slice, spoke) {
        if (spoke === void 0) { spoke = this.defaultSpokeName; }
        this.emit('slice-record-changes-test', { slice: slice }, { spoke: spoke });
    };
    AuxiliumSocketClient.prototype.offReportEvents = function (slice, spoke) {
        if (spoke === void 0) { spoke = this.defaultSpokeName; }
        console.log('@@TODO stop listening to report events...', slice, spoke);
    };
    AuxiliumSocketClient.prototype.offRecordChanges = function (slice, spoke) {
        if (spoke === void 0) { spoke = this.defaultSpokeName; }
        console.log('@@TODO stop listening to record changes...', slice, spoke);
    };
    AuxiliumSocketClient.prototype.offCommentChanges = function (slice, spoke) {
        if (spoke === void 0) { spoke = this.defaultSpokeName; }
        console.log('@@todo stop listening to comment changes', slice, spoke);
    };
    AuxiliumSocketClient.prototype.offSliceChanges = function (slice, spoke) {
        if (spoke === void 0) { spoke = this.defaultSpokeName; }
        console.log('@@todo stop listening to slice changes', slice, spoke);
    };
    AuxiliumSocketClient.prototype._onReportEvents = function (evt) {
        var cache = this._onReportEventsObservers, slices = cache[evt.spoke], existing = slices ? slices.get(evt.slice) : false;
        if (existing) {
            existing.next(evt.data);
        }
        else {
            this.offReportEvents(evt.slice, evt.spoke);
        }
    };
    AuxiliumSocketClient.prototype._onSliceChanges = function (evt) {
        var cache = this._onSliceChangesObservers, slices = cache[evt.spoke], existing = slices ? slices.get(evt.slice) : false;
        if (existing) {
            existing.next(evt.data);
        }
        else {
            this.offSliceChanges(evt.slice, evt.spoke);
        }
    };
    AuxiliumSocketClient.prototype._onCommentChanges = function (evt) {
        var cache = this._onCommentChangesObservers, slices = cache[evt.spoke], existing = slices ? slices.get(evt.slice) : false;
        if (existing) {
            existing.next(evt.data);
        }
        else {
            this.offCommentChanges(evt.slice, evt.spoke);
        }
    };
    AuxiliumSocketClient.prototype._onRecordChanges = function (evt) {
        var cache = this._onRecordChangesObservers, slices = cache[evt.spoke], existing = slices ? slices.get(evt.slice) : false;
        if (existing) {
            existing.next(evt.data);
        }
        else {
            this.offRecordChanges(evt.slice, evt.spoke);
        }
    };
    return AuxiliumSocketClient;
}(_AuxiliumSocketClientBase));
export { AuxiliumSocketClient };
